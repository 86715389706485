import React from 'react';
import { StyledHeading } from './ErrorTemplate.style';

const ErrorTemplate = () => {
  return (
    <StyledHeading
      style={{
        height: '60vh',
        marginTop: '110px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Podana strona nie istnieje !
    </StyledHeading>
  );
};

export default ErrorTemplate;
