import styled from 'styled-components';

export const StyledHeading = styled.h1`
  margin: 3rem auto 3rem auto;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.font.size.l};
  text-transform: uppercase;
  letter-spacing: 0.6rem;
  font-weight: 100;

  ${({ theme }) => theme.mq.tablet} {
    margin: 7rem auto 5rem auto;
    font-size: ${({ theme }) => theme.font.size.xl};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.xxl};
    margin: 7rem auto;
  }
`;
