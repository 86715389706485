import React from 'react';
import MediaQuery from 'react-responsive';
import SEO from '../components/SEO/SEO';
import Header from '../components/Header/Header';
import MainTemplate from '../templates/MainTemplate';
import ErrorTemplate from '../templates/ErrorTemplate';
import FooterTemplate from '../templates/FooterTemplate';
import NavButton from '../components/NavButton';

const ErrorPage = () => {
  return (
    <MainTemplate>
      <SEO title="404: Not found" />
      <Header hashPart={false} />
      <MediaQuery maxWidth={1020}>
        <NavButton />
      </MediaQuery>

      <ErrorTemplate />
      <FooterTemplate iconSize={42} />
    </MainTemplate>
  );
};

export default ErrorPage;
